import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/website/src/components/DocsLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<inlineCode parentName="p">{`Controller`}</inlineCode>{` component is an optional wrapper for the `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` that automatically configures time and playback controls.`}</p>
    <p>{`It can manage the `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` invisibly (for instance, if you want your animation to play automatically), but the `}<inlineCode parentName="p">{`Controller`}</inlineCode>{` also provides an animation playback UI that works without any configuration.
You can replace this playback control UI with custom elements as well.`}</p>
    <h2 {...{
      "id": "import"
    }}>{`Import`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Controller } from "react-ensemble";
`}</code></pre>
    <h2 {...{
      "id": "interface"
    }}>{`Interface`}</h2>
    <p><inlineCode parentName="p">{`Controller`}</inlineCode>{` is a React component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const Controller = <State extends object = any>(
  props: ControllerProps<State>
): JSX.Element | null;
`}</code></pre>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`ControllerProps<State extends object = any>`}</inlineCode></li>
      <li parentName="ul">{`The optional generic type parameter `}<inlineCode parentName="li">{`State`}</inlineCode>{` refers to the structure of the animation's state. Must be an object.`}</li>
    </ul>
    <p>{`Properties for the `}<inlineCode parentName="p">{`Controller`}</inlineCode>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`interface ControllerProps<State extends object = any> {
  children: (
    controlProps: Pick<
      TimelineProps<State>,
      "value" | "playing" | "playbackSpeed" | "onTick" | "onEnded" | "onLoad"
    >
  ) => JSX.Element;
  visible?: boolean;
  trigger?: "manual" | "auto";
  normalPlaybackSpeed?: number;
  fastForwardModifier?: number;
  reverseModifier?: number;
  panelStyle?: object;
  panel?: (props: ControlPanelProps) => JSX.Element;
}
`}</code></pre>
    <h3 {...{
      "id": "children"
    }}>{`children`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`(controlProps: Pick<TimelineProps<State>, ...>) => JSX.Element`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Required`}</strong></li>
    </ul>
    <p>{`Provides a subset of configured `}<a parentName="p" {...{
        "href": "/docs/api/timeline#props"
      }}><inlineCode parentName="a">{`TimelineProps`}</inlineCode></a>{` that can be used to manage a `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` automatically.`}</p>
    <p>{`The configured props include:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`value`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`playing`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`playbackSpeed`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`onTick`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`onEnded`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`onLoad`}</inlineCode></li>
    </ul>
    <p><inlineCode parentName="p">{`Controller`}</inlineCode>{` will configure the value of these timeline properties based on its own props, including `}<inlineCode parentName="p">{`trigger`}</inlineCode>{`, `}<inlineCode parentName="p">{`normalPlaybackSpeed`}</inlineCode>{`, and `}<inlineCode parentName="p">{`panel`}</inlineCode>{`.`}</p>
    <p>{`This type of prop is called a `}<strong parentName="p">{`render prop`}</strong>{` because the `}<inlineCode parentName="p">{`Controller`}</inlineCode>{` uses it to know what to render. Use it by putting a function in between your `}<inlineCode parentName="p">{`<Controller>`}</inlineCode>{` tags:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Controller>
  {props => (
    <Timeline {...props} track={myTrack} defaultState={myDefaultState} />
  )}
</Controller>
`}</code></pre>
    <h3 {...{
      "id": "visible"
    }}>{`visible`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`boolean`}</inlineCode></li>
      <li parentName="ul">{`Default: `}<inlineCode parentName="li">{`true`}</inlineCode></li>
    </ul>
    <p>{`Whether the playback `}<inlineCode parentName="p">{`panel`}</inlineCode>{` is visible.`}</p>
    <h3 {...{
      "id": "trigger"
    }}>{`trigger`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`"manual" | "auto"`}</inlineCode></li>
      <li parentName="ul">{`Default: `}<inlineCode parentName="li">{`"manual"`}</inlineCode></li>
    </ul>
    <p>{`Whether the configured `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` should play automatically or wait for a manual trigger.`}</p>
    <h3 {...{
      "id": "normalplaybackspeed"
    }}>{`normalPlaybackSpeed`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`number`}</inlineCode></li>
      <li parentName="ul">{`Default: `}<inlineCode parentName="li">{`1`}</inlineCode></li>
    </ul>
    <p>{`The `}<inlineCode parentName="p">{`playbackSpeed`}</inlineCode>{` to pass to the configured `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` via the `}<inlineCode parentName="p">{`children`}</inlineCode>{` render prop when the `}<inlineCode parentName="p">{`panel`}</inlineCode>{` is in normal playback mode.`}</p>
    <h3 {...{
      "id": "fastforwardmodifier"
    }}>{`fastForwardModifier`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`number`}</inlineCode></li>
      <li parentName="ul">{`Default: `}<inlineCode parentName="li">{`2`}</inlineCode></li>
    </ul>
    <p>{`Is multiplied with `}<inlineCode parentName="p">{`normalPlaybackSpeed`}</inlineCode>{` to calculate the `}<inlineCode parentName="p">{`playbackSpeed`}</inlineCode>{` passed to the configured `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` when the `}<inlineCode parentName="p">{`panel`}</inlineCode>{` is in fast-forward playback mode.`}</p>
    <h3 {...{
      "id": "reversemodifier"
    }}>{`reverseModifier`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`number`}</inlineCode></li>
      <li parentName="ul">{`Default: `}<inlineCode parentName="li">{`-2`}</inlineCode></li>
    </ul>
    <p>{`Is multiplied with `}<inlineCode parentName="p">{`normalPlaybackSpeed`}</inlineCode>{` to calculate the `}<inlineCode parentName="p">{`playbackSpeed`}</inlineCode>{` passed to the configured `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` when the `}<inlineCode parentName="p">{`panel`}</inlineCode>{` is in reverse playback mode.`}</p>
    <h3 {...{
      "id": "panelstyle"
    }}>{`panelStyle`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`object`}</inlineCode></li>
      <li parentName="ul">{`Default: `}<inlineCode parentName="li">{`{}`}</inlineCode></li>
    </ul>
    <p>{`The CSS style object passed to the `}<inlineCode parentName="p">{`<div>`}</inlineCode>{` that contains the `}<inlineCode parentName="p">{`panel`}</inlineCode>{`.`}</p>
    <p>{`This prop is best suited for minor style adjustments. For more granular control over the panel's appearance, override the `}<inlineCode parentName="p">{`panel`}</inlineCode>{` prop instead.`}</p>
    <h3 {...{
      "id": "panel"
    }}>{`panel`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`(props: ControlPanelProps) => JSX.Element;`}</inlineCode></li>
      <li parentName="ul">{`Default `}<a parentName="li" {...{
          "href": "/docs/api/simplecontrolpanel"
        }}><inlineCode parentName="a">{`SimpleControlPanel`}</inlineCode></a></li>
    </ul>
    <p>{`Renders a generic playback control panel for an animation based on props configured by `}<inlineCode parentName="p">{`Controller`}</inlineCode>{` and its managed `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{`.`}</p>
    <p>{`Provides all the data necessary to render a responsive playback control with play/pause, fast-forward, reverse, and progress bar features.
Includes both current information about the managed `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` and a suite of callback functions to control it. See `}<a parentName="p" {...{
        "href": "/docs/api/types#controlpanelprops"
      }}><inlineCode parentName="a">{`ControlPanelProps`}</inlineCode></a>{`.`}</p>
    <p>{`Will render a basic, unstyled control panel by default intended for demo purposes. See `}<a parentName="p" {...{
        "href": "/docs/api/simplecontrolpanel"
      }}><inlineCode parentName="a">{`SimpleControlPanel`}</inlineCode></a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      